<template>
    <div class="faq-list">
      <div class="faq-item" v-for="item in faqContent" :key="item.id" :class="{'is-active': expandAll || selectedFaqItem === item.id}">
        <div class="question">
          <div class="text">{{ item.question }}</div>
          <span class="close" v-if="!expandAll" @click="onCloseClicked(item.id)">+</span>
        </div>
        <div class="answer" v-if="expandAll || selectedFaqItem === item.id">
          <div class="text" v-html="item.answer"></div>
        </div>
      </div>
    </div>
</template>
<script setup lang="ts">
import { useLocalePath } from "#i18n";
const localePath = useLocalePath();
const { t } = useI18n();
const props = defineProps({
  faqContent: Array,
  expandAll: {
    type: Boolean,
    default: false,
  }
})

const selectedFaqItem = ref();

function onCloseClicked(itemId: string) {
  if (selectedFaqItem.value === itemId) {
    selectedFaqItem.value = null;
  } else {
    selectedFaqItem.value = itemId;
  }
}
</script>
